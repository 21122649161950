import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "docz-gatsby-example"
    }}>{`Docz Gatsby Example`}</h1>
    <p>{`This example shows you how you can use docz as a `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/blog/2018-11-11-introducing-gatsby-themes/"
      }}>{`Gatsby Theme`}</a>{` inside your project.`}</p>
    <h2 {...{
      "id": "using-create-docz-app"
    }}>{`Using `}<inlineCode parentName="h2">{`create-docz-app`}</inlineCode></h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npx create-docz-app docz-app-gatsby --example gatsby
# or
yarn create docz-app docz-app-gatsby --example gatsby
`}</code></pre>
    <h2 {...{
      "id": "download"
    }}>{`Download`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl https://codeload.github.com/doczjs/docz/tar.gz/master | tar -xz --strip=2 docz-master/examples/gatsby
mv gatsby docz-gatsby-example
cd docz-gatsby-example
`}</code></pre>
    <h2 {...{
      "id": "setup"
    }}>{`Setup`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn # npm i
`}</code></pre>
    <h2 {...{
      "id": "start-developing"
    }}>{`Start developing`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn dev # npm run dev
`}</code></pre>
    <h2 {...{
      "id": "build"
    }}>{`Build`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn build # npm run build
`}</code></pre>
    <h2 {...{
      "id": "serve-built-app"
    }}>{`Serve built app`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn serve # npm run serve
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      